@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~rsuite/dist/rsuite.min.css";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.c-ptr {
  cursor: pointer;
}
.listPanel {
  margin-bottom: 3px;
  .rs-panel-body {
    padding: 10px !important;
  }
}
.dropdown-width {
  width: 200px !important;
  button {
    width: 200px !important;
  }
}

/// Sidebar Styles
.pro-sidebar::-webkit-scrollbar {
  display: none !important;
}

.pro-sidebar .pro-menu .pro-menu-item.active {
  color: #56c7ec !important;
}
.pro-sidebar .pro-menu .pro-menu-item.active {
  div {
    span {
      color: #56c7ec !important;
    }
  }
  color: #56c7ec !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  color: #56c7ec !important ;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
  outline: none;
  color: #56c7ec !important;
}

.env-text {
  color: red;
  font-weight: 700;
  position: fixed;
  bottom: 24px;
  right: 24px;
}



.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  overflow-y: overlay !important;
}

/* width */
.pro-sidebar-layout::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.pro-sidebar-layout::-webkit-scrollbar-track {
  background: #242424;
}

/* Handle */
.pro-sidebar-layout::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.pro-sidebar-layout::-webkit-scrollbar-thumb:hover {
  background: #555;
}
